// 
// type.scss
//

// Text colors
.text-white-75 {
    color: rgba($white, 0.75) !important;
}

// Blockquote
.custom-blockquote {
    &.blockquote {
        padding: 16px;
        border-left: 3px solid;
        
        &.blockquote-outline{
            background-color: var(--#{$prefix}card-bg-custom) !important;
            border: 1px solid;
            border-left: 3px solid;
        }
    }
}

@each $color,
$value in $theme-colors {
    .custom-blockquote {
        &.blockquote {
            &.blockquote-#{$color} {
                color: #{$value};
                border-color: #{$value};
                background-color: rgba($value, 0.15);

                .blockquote-footer {
                    color: #{$value};
                }
            }

            &.blockquote-outline{
                &.blockquote-#{$color} {
                    border-color: #{$value};

                    .blockquote-footer {
                        color: #{$value};
                    }
                }
            }
        }
    }
}

